<template>
  <div class="container">
    <b-loading v-model="isLoading"></b-loading>
    <div v-if="orderSuccess && paymentMethod == 6" class="">
      <div class="block box has-background-primary has-text-white">
        <p>Twoje zamówienie zostało zarejestrowane!</p>
        <p>Dziekujemy za skorzystanie z naszej oferty.</p>
      </div>
      <div class="block">
                <b-button type="is-primary" outlined @click.prevent="$router.push({ name: 'Cars' })">
          Wróć do strony głównej
        </b-button>
      </div>
    </div>
    <div v-if="orderSuccess && paymentMethod != 6" class="">
      <div class="block box has-background-primary has-text-white">
        <p>Twoje zamówienie zostało zarejestrowane!</p>
        <p>Dziekujemy za skorzystanie z naszej oferty.</p>
      </div>
      <div class="block">
        <b-button type="is-primary" outlined @click="toPaymentLink">
          Opłać zamówienie
        </b-button>
        <br />
        <br />
        <b-button
          type="is-dark"
          outlined
          tag="router-link"
          :to="{ name: 'ExploratorStartSearch' }"
        >
          Powrót na stronę główną
        </b-button>
      </div>
    </div>
    <div v-else class="columns">
      <div class="column is-full">
        <b-steps v-model="activeStep" animated rounded>
          <template slot="navigation" slot-scope="{ next }">
            <div class="columns is-centered">
              <div class="column is-2 has-text-centered">
                <b-button
                  type="is-dark"
                  icon-left="arrow-left-bold"
                  @click.prevent="$router.push({ name: 'Cars' })"
                  rounded
                >
                  Cofnij
                </b-button>
              </div>
              <div class="column is-2 has-text-centered">
                <b-button
                  type="is-primary"
                  icon-right="arrow-right-bold"
                  :disabled="isNextStepDisabled"
                  @click.prevent="next.action"
                  rounded
                >
                  Dalej
                </b-button>
              </div>
            </div>
          </template>

          <b-step-item step="1" label="Samochód" icon="car">
            <div class="columns is-centered">
              <div class="column is-half">
                <car-picker></car-picker>
              </div>
            </div>
          </b-step-item>

          <b-step-item step="2" label="Usługi" icon="tools">
            <div class="columns">
              <div class="column is-three-quarters">
                <div v-for="(category, name) in companyServices" :key="name">
                  <service-picker
                    :title="name"
                    :services="category"
                  ></service-picker>
                </div>
              </div>
              <div class="column is-one-quarter">
                <div style="position: sticky; top: 20px">
                  <dynamic-value-counter></dynamic-value-counter>
                </div>
              </div>
            </div>
          </b-step-item>
          <b-step-item step="3" label="Data rejestracji" icon="calendar">
            <service-order-calendar
              v-model="selectedDate"
            ></service-order-calendar>
          </b-step-item>
          <b-step-item step="4" label="Płatność" icon="cash">
            <payment-picker @setPayment="setPayment"></payment-picker>
          </b-step-item>
          <b-step-item step="5" label="Podsumowanie" icon="clipboard-check">
            <div class="block">
              <order-creation-summary></order-creation-summary>
            </div>
            <div class="columns is-centered mb-3">
              <b-button type="is-primary" @click="createOrder" rounded
                >Złóż zamówienie</b-button
              >
            </div>
          </b-step-item>
        </b-steps>
      </div>
    </div>
  </div>
</template>
<script>
import CarPicker from "@/components/car-data/CarPicker";
import ServicePicker from "@/components/ServicePicker";
import DynamicValueCounter from "@/components/DynamicValueCounter";
import OrderCreationSummary from "@/components/OrderCreationSummary";
import ServiceOrderCalendar from "@/components/ServiceOrderCalendar";
import PaymentPicker from "@/components/car-data/PaymentPicker";

export default {
  components: {
    "car-picker": CarPicker,
    "service-picker": ServicePicker,
    "dynamic-value-counter": DynamicValueCounter,
    "order-creation-summary": OrderCreationSummary,
    "service-order-calendar": ServiceOrderCalendar,
    "payment-picker": PaymentPicker,
  },

  created() {
    this.$store.dispatch("clientCars/getCars");
  },

  data() {
    return {
      orderSuccess: false,
      isLoading: false,
      activeStep: 0,
      isDateEditing: false,
      selectedDate: new Date(),
      selectedPayment: null,
      paymentLink: "",
    };
  },

  methods: {
    toPaymentLink() {
      //console.log("pl", this.paymentLink);
      window.location.href = this.paymentLink;
    },
    setPayment(e) {
      this.selectedPayment = e;
    },
    createOrder() {
      this.isLoading = true;

      this.$store
        .dispatch("appcarsService/createOrder")
        .then((response) => {
          //console.log(response)
          if (response.status === 201) {
            this.orderSuccess = true;
            this.paymentLink = response.data.content.payment_link || '';
            window.scrollTo(0,0)
            setTimeout(() => {
              this.$router.push({name: 'Orders'})
            }, 500)
          } else {
            this.$buefy.notification.open({
              message: `Nie udało się utworzyć zamówienia. Spróbuj później lub skontaktuj się z administratorem. ${response.data.content}`,
              type: "is-danger",
              position: "is-top",
              duration: 60000,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.orderSuccess = false;
          this.$buefy.notification.open({
            message:
              "Nie udało się utworzyć zamówienia. Spróbuj później lub skontaktuj się z administratorem",
            type: "is-danger",
            position: "is-top",
            duration: 60000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  computed: {
    isNextStepDisabled() {
      const order = this.$store.getters["appcarsService/order"];

      if (this.activeStep === 0) {
        return Object.keys(order.car).length === 0;
      } else if (this.activeStep === 1) {
        if (Object.keys(order.services).length === 0) {
          return true;
        } else {
          let result = true;

          for (const serviceCategory in order.services) {
            if (order.services[serviceCategory].length === 0) {
              continue;
            }

            const anyVariantSelected = order.services[serviceCategory].some(
              (service) => {
                return service.checked && service.variant !== null;
              }
            );

            return !anyVariantSelected;
          }

          return result;
        }
      }
      if (this.activeStep == 2 && this.selectedDate != null) {
        return false;
      }
      if (this.activeStep == 3 && this.selectedPayment != null) {
        return false;
      }

      return true;
    },
    paymentMethod() {
      return this.$store.getters["appcarsService/pickPayment"];
    },
    companyServices() {
      return this.$store.getters["companyData/getCompanyServices"];
    },

    profile() {
      return this.$store.getters["clientProfile/profile"];
    },

    contextCompany() {
      return this.$store.getters["appcarsService/contextCompany"];
    },
  },
};
</script>