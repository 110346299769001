<template>
  <div class="box">
    <div class="level">
      <div class="level-left">Cena końcowa:</div>
      <div class="level-right">
        <b-tag type="is-primary" size="is-small">
          {{ priceToString(orderValuation) }}
        </b-tag>
      </div>
    </div>
    <div>
      <div
        v-for="(category, name) in selectedServices"
        :key="`category-${name}`"
      >
        <div>{{ name }}</div>
        <div v-for="(service, index) in category" :key="`service-${index}`">
          {{ service.name }} - {{ service.variant.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    priceToString(price) {
      return `${price} zł`;
    },
  },

  computed: {
    orderValuation() {
      return this.$store.getters["appcarsService/orderValuation"];
    },

    selectedServices() {
      return this.$store.getters["appcarsService/selectedServices"];
    },
  },
};
</script>