<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="block">
          <h1 class="title is-5 mb-2">Wybrany termin:</h1>
          <div class="card">
            <div class="card-content">
              {{ selectedDay }}, {{ selecetedTime }}
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="block">
          <h1 class="title is-5 mb-2">Wybrany samochód:</h1>
          <div class="card">
            <div class="card-content" v-if="order.car.car_details.serie">
              {{ order.car.car_details.serie.model.brand.name + ' ' + order.car.car_details.serie.model.name || "-" }}
              ({{ order.car.car_details.serie.name || "-" }})
            </div>
            <div class="card" v-else>-</div>
          </div>          
        </div>
      </div>
    </div>

    <div class="block">
      <h1 class="title is-5 mb-2">Dane kontaktowe:</h1>
      <div class="columns">
        <div class="column is-half">
          <div class="box">
            <div class="columns is-centered">
              <div class="column is-1 has-text-right">
                <b-icon icon="account"></b-icon>
              </div>
              <div class="column is-5">
                <div class="block">
                  <p>
                    {{ profile.details.first_name }}
                    {{ profile.details.last_name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-1 has-text-right">
                <b-icon icon="home"></b-icon>
              </div>
              <div class="column is-5">
                <div class="block">
                  <p>Miejscowość: {{ profile.address[0].city }}</p>
                  <p>Kod pocztowy: {{ profile.address[0].postcode }}</p>
                  <p>Ulica: {{ profile.address[0].street }}</p>
                  <p>Nr domu/lokalu: {{ profile.address[0].local_nb }}</p>
                </div>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-1 has-text-right">
                <b-icon icon="email"></b-icon>
              </div>
              <div class="column is-5">
                <div class="block">
                  <p>Email: {{ profile.email }}</p>
                </div>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-1 has-text-right">
                <b-icon icon="phone"></b-icon>
              </div>
              <div class="column is-5">
                <div class="block">
                  <p>Telefon: {{ profile.details.phone }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="box">
            <div class="columns is-centered">
              <div class="column is-1 has-text-right">
                <b-icon icon="account"></b-icon>
              </div>
              <div class="column is-5">
                <div class="block">
                  <p>{{ contextCompany.name || "-" }}</p>
                </div>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-1 has-text-right">
                <b-icon icon="home"></b-icon>
              </div>
              <div class="column is-5">
                <div class="block">
                  <p>Miejscowość: {{ contextCompany.address[0].city }}</p>
                  <p>Kod pocztowy: {{ contextCompany.address[0].postcode }}</p>
                  <p>Ulica: {{ contextCompany.address[0].street }}</p>
                  <p>
                    Nr domu/lokalu: {{ contextCompany.address[0].local_nb }}
                  </p>
                </div>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-1 has-text-right">
                <b-icon icon="email"></b-icon>
              </div>
              <div class="column is-5">
                <div class="block">
                  <p>Email: {{ contextCompany.email }}</p>
                </div>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-1 has-text-right">
                <b-icon icon="phone"></b-icon>
              </div>
              <div class="column is-5">
                <div class="block">
                  <p>Telefon: {{ contextCompany.details.phone }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block">
      <h1 class="title is-5 mb-2">Wybrane usługi:</h1>
      <div class="columns is-multiline">
        <div
          class="column is-half"
          v-for="(category, name) in order.services"
          :key="`category-${name}`"
        >
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                {{ name || "-" }}
              </div>
            </div>
            <div class="card-content">
              <p v-for="(service, index) in category" :key="`service-${index}`">
                <span v-if="service.variant !== null">
                  {{ service.name || "-" }} -
                  {{ service.variant.name || "-" }} -
                  <b-tag type="is-primary">{{ service.variant.value }}</b-tag>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h1 class="title is-5 mb-5">Wybrana płatność:</h1>
    <div class="columns is-multiline">
      <div>
        <div class="card ml-3">
          <div class="card-header">
            <div class="card-header-title">Rodzaj</div>
          </div>
          <div class="card-content" v-if="payment">
            <p>
              {{ payment.name || "-" }}
              <b-tag type="is-primary" size="is-small"
                ><span v-if="payment.percent != 0">{{ (orderValuation * payment.percent).toFixed(2) }} zł</span>
                  <span v-else>-</span>
                </b-tag
              >
            </p>
            <div v-if="payment.id == 6">
                <p><b>Bank</b> {{name}}</p>
                <p><b>Nr konta</b> {{number}}</p>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block box">
      <div class="level">
        <div class="level-right">
          <div class="level-item title is-5">Cena końcowa:</div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-tag type="is-primary" size="is-medium"
              >{{ orderValuation }} zł</b-tag
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  created() {
    //console.log(this.order)
  },
  data() {
    return {
      payments: [
        {
          id: 6,
          percent: 0,
          name: "Płatność przy odbiorze usługi lub przelewem",
        },
        {
          id: 4,
          percent: 0.1,
          name: "Płatność 10% wartości zamówienia",
        },
        {
          id: 3,
          percent: 0.25,
          name: "Płatność 25% wartości zamówienia",
        },
        {
          id: 2,
          percent: 0.5,
          name: "Płatność 50% wartości zamówienia",
        },
        {
          id: 1,
          percent: 1,
          name: "Płatność 100% wartości zamówienia",
        },
      ],
    };
  },

  computed: {

        name(){
      return this.$store.getters["appcarsService/contextCompany"].details.bank_name;
    },
    number(){
      return this.$store.getters["appcarsService/contextCompany"].details.bank_number;
    },
    payment() {
      return this.payments.filter((p) => {
        return p.id == this.$store.state.appcarsService.order_payment_type_id;
      })[0];
    },


    profile() {
      return this.$store.getters["clientProfile/profile"];
    },

    contextCompany() {
      return this.$store.getters["appcarsService/contextCompany"];
    },

    order() {
      return this.$store.getters["appcarsService/order"];
    },

    orderValuation() {
      return this.$store.getters["appcarsService/orderValuation"];
    },

    selectedDate() {
      return (
        this.$store.getters["appcarsService/order"].selectedDate || new Date()
      );
    },

    selectedDay() {
      if (this.selectedDate === null) {
        return "";
      }

      return `${this.selectedDate.getFullYear()  || new Date().getFullYear()}-${
        this.selectedDate.getMonth() + 1
      }-${this.selectedDate.getDate()}`;
    },

    selecetedTime() {
      if (this.selectedDate === null) {
        return "";
      }

      let hoursStr = this.selectedDate.getHours();

      if (hoursStr < 10) {
        hoursStr = `0${hoursStr}`;
      }

      let minutesStr = this.selectedDate.getMinutes();

      if (minutesStr < 10) {
        minutesStr = `0${minutesStr}`;
      }

      return `${hoursStr}:${minutesStr}`;
    },
  },
};
</script>