<template>
  <div class="block">
    <div class="card">
      <div class="card-header has-background-dark">
        <div class="card-header-title has-text-white">
          {{ title }}
        </div>
      </div>
      <div class="card-content">
        <div
          class="card"
          v-for="(service, index) in services"
          :key="`${service.name}-${service.id}`"
        >
          <div class="card-content">
            <div class="columns">
              <div class="column is-two-fifths">
                <b-field grouped>
                  <b-checkbox
                    v-model="formServices[index].checked"
                    :native-value="service"
                  >
                    {{ service.name }}
                  </b-checkbox>
                </b-field>
              </div>
              <div class="column is-two-fifths">
                <b-field>
                  <b-select
                    v-model="formServices[index].variant"
                    :disabled="!formServices[index].checked"
                    @input="onVariantSelectChange()"
                    expanded
                  >
                    <option
                      v-for="variant in service.services"
                      :value="variant"
                      :key="`${variant.name}-${variant.id}`"
                    >
                      {{ variant.name }} - {{ variant.value }} zł
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-one-fifth">
                <b-tag
                  v-if="
                    formServices[index].variant && formServices[index].checked
                  "
                  type="is-primary"
                >
                  {{ formServices[index].variant.value }} zł
                </b-tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["typeId", "title", "services"],

  data() {
    return {
      formServices: [],
    };
  },

  computed: {},

  methods: {
    onVariantSelectChange() {
      this.$store.commit(`appcarsService/setOrderServices`, {
        category: this.title,
        services: this.formServices,
      });
    },
  },

  created() {
    this.formServices = this.services.map((service) => {
      return {
        checked: false,
        name: service.name,
        variant: null,
      };
    });
  },
};
</script>