<template>
  <div>
    <order-creator></order-creator>
  </div>
</template>

<script>
import OrderCreator from "@/components/OrderCreator";

export default {
  components: {
    "order-creator": OrderCreator,
  },

  created() {
    this.$store.dispatch(
      "appcarsService/getContextCompanyBySlug",
      this.$route.params.id
    );
  },
};
</script>