<template>
  <div>
    <div v-if="hasCars">
      <div class="card">
        <div class="card-content">
          <b-field label="Wybierz samochód" custom-class="subtitle is-4">
            <b-select
              v-model="selectedCar"
              placeholder="Pojazd"
              icon="car"
              size="is-medium"
              @input="onSelectValueChange"
            >
              <option v-for="car in cars" :value="car.id" :key="car.id">
                {{ car.car_details.serie.model.brand.name }}
                {{ car.car_details.serie.model.name }} -
                {{ car.car_details.serie.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card">
        <div class="card-content">
          <h4 class="title is-4">Nie posiadasz żadnych pojazdów</h4>
          <p class="block">
            Aby móc złożyć zamówienie, musisz dodać do systemu swój samochód
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedCar: null,
    };
  },

  methods: {
    onSelectValueChange() {
      this.$store.dispatch("appcarsService/pickCarForOrder", this.selectedCar);
    },
  },

  computed: {
    cars() {
      return this.$store.getters["clientCars/cars"];
    },

    hasCars() {
      return this.cars.length > 0;
    },
  },
};
</script>