<template>
  <div class="is-two-thirds">
    <div>
      <div class="card">
        <div class="card-content">
          <b-field
            label="Wybierz rodzaj płatności"
            custom-class="subtitle is-4"
          >
            <b-select
              v-model="selectedPayment"
              placeholder="Rodzaj płatności"
              icon="cash"
              size="is-medium"
              @input="onSelectValueChange"
            >
              <option
                v-for="payment in payments"
                :value="payment.id"
                :key="payment.id"
              >
                {{ payment.name }}
              </option>
            </b-select>
          </b-field>
          <div v-if="selectedPayment && selectedPayment != 6">
            Do zapłaty:
            <b-tag type="is-primary">
              {{ Number(orderValuation * paymentPercent).toFixed(2) }} zł</b-tag
            >
          </div>
        </div>
        <div class="box">
          <b-message
            title="Ważna informacja"
            v-if="selectedPayment && selectedPayment == 6"
            type="is-dark"
            has-icon
            icon="alert"
            aria-close-label="Close message"
          >
            Wybranie płatności przy odbiorze usługi nie gwarantuje akceptacji
            zamówienia i może zostać anulowane przez wykonawcę. Jeśli chcesz
            mieć pewność zamówienia terminu i usługi wybierz opcję z zaliczką
            zamówienia.
            <br/>
            W przypadku wykonania przelewu dane:
            <br/>
            <b>Nazwa banku:</b> {{ name  }}
            <br/>
            <b>Nr konta bankowego: </b> {{ number }}
          </b-message>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedPayment: null,
      paymentPercent: null,
      payments: [
        {
          id: 6,
          name: "Płatność przy odbiorze usługi lub przelewem",
        },
        {
          id: 4,
          percent: 0.1,
          name: "Płatność 10% wartości zamówienia",
        },
        {
          id: 3,
          percent: 0.25,
          name: "Płatność 25% wartości zamówienia",
        },
        {
          id: 2,
          percent: 0.5,
          name: "Płatność 50% wartości zamówienia",
        },
        {
          id: 1,
          percent: 1,
          name: "Płatność 100% wartości zamówienia",
        },
      ],
    };
  },

  methods: {
    onSelectValueChange() {
      this.paymentPercent = this.payments.filter(
        (p) => p.id == this.selectedPayment
      )[0].percent;
      this.$emit("setPayment", this.selectedPayment);
      this.$store.dispatch("appcarsService/pickPayment", this.selectedPayment);
    },
  },

  computed: {
    orderValuation() {
      return this.$store.getters["appcarsService/orderValuation"];
    },

    name(){
      return this.$store.getters["appcarsService/contextCompany"].details.bank_name;
    },
    number(){
      return this.$store.getters["appcarsService/contextCompany"].details.bank_number;
    }
  },
};
</script>